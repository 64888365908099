<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="41"
    viewBox="0 0 41 41"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g
                stroke="#FFF"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.55"
              >
                <path
                  d="M22.666 0H2.834C1.268 0 0 1.268 0 2.834V17"
                  transform="translate(-516.000000, -399.000000) translate(505.000000, 388.000000) translate(11.050000, 11.050000) translate(0.000000, -0.000000) translate(5.100000, 5.100000)"
                />
                <path
                  d="M17.85 30.6l2.834-5.666h7.084c1.564 0 2.832-1.27 2.832-2.834V7.934c0-1.566-1.268-2.834-2.834-2.834H7.934C6.368 5.1 5.1 6.368 5.1 7.934V22.1c0 1.564 1.268 2.834 2.834 2.834H13.6M11.9 11.9L23.8 11.9M11.9 18.7L18.7 18.7"
                  transform="translate(-516.000000, -399.000000) translate(505.000000, 388.000000) translate(11.050000, 11.050000) translate(0.000000, -0.000000) translate(5.100000, 5.100000)"
                />
              </g>
              <path
                d="M0 0L40.8 0 40.8 40.8 0 40.8z"
                transform="translate(-516.000000, -399.000000) translate(505.000000, 388.000000) translate(11.050000, 11.050000) translate(0.000000, -0.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconChat",
};
</script>

<style></style>
